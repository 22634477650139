/**
 * 球员
 */
import { SideBar } from "antd-mobile"
import { useEffect, useState } from "react"
import Assist from "../component/assist"
import FreeKick from "../component/freeKick"
import Red from "../component/red"
import Yellow from "../component/yellow"
import Score from "../component/score"
import "./scrum.scss"

import ScrumConst from "../consts/scrum";

export default function Scrum({ seasonCode }) {

  const [currentBar, setCurrentBar] = useState(ScrumConst.BAR_1);
  const [tabs, setTabs] = useState([
    {
      key: ScrumConst.BAR_1,
      title: '射手',
    },
    // {
    //   key: ScrumConst.BAR_2,
    //   title: '助攻',
    // },
    {
      key: ScrumConst.BAR_3,
      title: '进任意球',
    },
    {
      key: ScrumConst.BAR_4,
      title: '红牌',
    },
    {
      key: ScrumConst.BAR_5,
      title: '黄牌',
    },
  ])

  const renderScrumContent = (currentBar) => {
    if (currentBar === ScrumConst.BAR_1) {
      return <Score seasonCode={seasonCode}></Score>
    } else if (currentBar === ScrumConst.BAR_2) {
      return <Assist seasonCode={seasonCode}></Assist>
    } else if (currentBar === ScrumConst.BAR_3) {
      return <FreeKick seasonCode={seasonCode}></FreeKick>
    } else if (currentBar === ScrumConst.BAR_4) {
      return <Red seasonCode={seasonCode}></Red>
    } else if (currentBar === ScrumConst.BAR_5) {
      return <Yellow seasonCode={seasonCode}></Yellow>
    }

  }

  return (
    <div className="scrum-body">
      <div className='slide-nav'>
        <SideBar style={{ '--width': '80px' }} onChange={setCurrentBar}>
          {tabs.map(item => (
            <SideBar.Item
              key={item.key}
              title={item.title}
            />
          ))}
        </SideBar>
      </div>
      <div className="scrum-content">
        {renderScrumContent(currentBar)}
      </div>
    </div>
  )
}

import { useEffect, useRef, useState } from 'react';
import './course.scss';

import { getCourse } from "../api/api"
import moment from 'moment'
import { Modal } from 'antd-mobile';
/**
 * 赛程
 */
export default function Course({ seasonCode }) {
  useEffect(() => {
    getCourse(seasonCode).then(data => {
      setList(data.data)
    })
    return () => { };
  }, [])
  useRef()
  const [list, setList] = useState([
    // { date: '2019-08-31', time: '7:30', teamOne: 'React', teamOneScore: 1, teamTwo: 'Vue', teamTwoScore: 2, teamOneLogo: "https://t13.baidu.com/it/u=2918543784,4063341038&fm=58&app=10&size=w931&q=75&n=0&f=PNG", teamTwoLogo: "https://t13.baidu.com/it/u=2918543784,4063341038&fm=58&app=10&size=w931&q=75&n=0&f=PNG" },
  ])

  const renderList = () => {
    let f = 0;
    return (
      <>
        {list.map(o => {
          if (f !== o.next) {
            f = o.next;
            return <><div className="item">
              <div className='field field1 next-title'>第{o.next}轮</div>
            </div>
              <div className="item" onClick={() => {
                o.content !== "" &&
                  Modal.show({
                    content: <>
                      <p className='course-info'>{o.teamOne} {o.teamOneScore} - {o.teamTwoScore} {o.teamTwo}</p>
                      <p className='course-info'>2024赛季 第{o.next}轮</p>
                      <div dangerouslySetInnerHTML={{ __html: o.content }}></div>
                    </>,
                    closeOnMaskClick: true,
                  })

              }}>
                <div className='field field1'>{moment(o.date).format("MM-DD HH:mm")}</div>
                <div className='field field2 course-team'>

                  <span>{o.teamOne}</span>
                  <div className='team-logo' style={{ backgroundImage: `url('${o.teamOneLogo}')` }}></div>
                </div>
                <div className='field field3'>
                  {o.status == 1 && <>{o.teamOneScore} - {o.teamTwoScore}</>}
                  {o.status == 0 && <>-</>}
                </div>
                <div className='field field4 course-team'>
                  <div className='team-logo' style={{ backgroundImage: `url('${o.teamTwoLogo}')` }}></div>
                  <span>{o.teamTwo}</span>
                </div>
              </div>
            </>;
          } else {
            return <div className="item" onClick={() => {
              o.content !== "" &&
                Modal.show({
                  content: <>
                    <p className='course-info'>{o.teamOne} {o.teamOneScore} - {o.teamTwoScore} {o.teamTwo}</p>
                    <p className='course-info'>2024赛季 第{o.next}轮</p>
                    <div dangerouslySetInnerHTML={{ __html: o.content }}></div>
                  </>,
                  closeOnMaskClick: true,
                })

            }}>
              <div className='field field1'>{moment(o.date).format("MM-DD HH:mm")}</div>
              <div className='field field2 course-team'>
                <span>{o.teamOne}</span>
                <div className='team-logo' style={{ backgroundImage: `url('${o.teamOneLogo}')` }}></div>
              </div>
              <div className='field field3'>
                {o.status == 1 && <>{o.teamOneScore} - {o.teamTwoScore}</>}
                {o.status == 0 && <>-</>}
              </div>
              <div className='field field4 course-team'>
                <div className='team-logo' style={{ backgroundImage: `url('${o.teamTwoLogo}')` }}></div>
                <span>{o.teamTwo}</span>
              </div>
            </div>
          }
        })}
      </>
    )
  }
  return (
    <div>
      <div className="table-header">
        <div className='field field1'>时间</div>
        <div className='field field2 course-team'>主队</div>
        <div className='field field3'>比分</div>
        <div className='field field4 course-team'>客队</div>
      </div>
      <div className="table-content">
        {renderList()}
      </div>
    </div>
  )
}
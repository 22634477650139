const BAR_1 = "score";
const BAR_2 = "assist";
const BAR_3 = "free";
const BAR_4 = "red";
const BAR_5 = "yellow";


export default {
  BAR_1,
  BAR_2,
  BAR_3,
  BAR_4,
  BAR_5
};
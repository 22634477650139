
import './App.scss';
import { Tabs } from 'antd-mobile'
import Scrum from './pages/scrum';
import Course from './pages/course';
import Team from './pages/team';
import Credit from './pages/credit';
import { useState } from 'react';


const tabIndex = {
  credit: Credit,
  course: Course,
  scrum: Scrum,
  team: Team
}


function App() {
  const [season, setSeason] = useState('s2024')
  const [currentTab, setCurrentTab] = useState('credit')

  const contentComponent = ({ condition }) => {
    if (condition === 'credit') {
      return <Credit seasonCode={season} />;
    } else if (condition === 'course') {
      return <Course seasonCode={season} />;
    } else if (condition === 'scrum') {
      return <Scrum seasonCode={season} />;
    } else if (condition === 'team') {
      return <Team seasonCode={season} />;
    }
  };

  const switchTab = (e) => {
    setCurrentTab(e);
  }
  return (
    <div className="App">
      <div className='banner'>
        <img className='image' style={{ width: "100%" }} src="https://magimg.daxiangshui.com//football/banner.jpg"></img>
      </div>
      <div className='header'>
        <div className='season-block'>2024赛季</div>
        <div className='transverse-nav'>
          <Tabs defaultActiveKey='credit' onChange={switchTab}>
            <Tabs.Tab title='积分' key='credit' />
            <Tabs.Tab title='赛程' key='course' />
            <Tabs.Tab title='球员' key='scrum' />
            <Tabs.Tab title='球队' key='team' />
          </Tabs>
        </div>
      </div>
      <div className='content'>
        {contentComponent({ condition: currentTab })}
      </div>
    </div>
  );
}

export default App;

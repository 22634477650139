import { useEffect, useState } from "react";
import { getTeamScrum } from "../api/api";
import { DotLoading } from "antd-mobile";
import "./team-scrum.scss"

export default function TeamScrum({ seasonCode, team }) {

  const [laoding, setLoading] = useState(false);

  useEffect(() => {
    getTeamScrum(seasonCode, team.teamCode).then(data => {
      setList(data.data);
      setLoading(false)
    });

    return () => {
      setLoading(true)
    }
  }, [seasonCode, team])
  const [list, setList] = useState([])
  return (
    <>
      {laoding && <DotLoading color='primary' />}
      {!laoding && <div>
        <div className="team-scrum-table-header">

          <div className='field field2 team-name'> <div className='team-logo' style={{ backgroundImage: `url('${team.teamLogo}')` }}></div>{team.teamName} <span className="rank">积分榜第{team.rank}位</span></div>

        </div>
        <div className="team-scrum-table-header">

          <div className='field field2'>球员</div>
          <div className='field field3'>得分/点球</div>
          <div className='field field3'>黄/红</div>
        </div>
        <div className="team-scrum-table-content">

          {list.map(o => (
            <div className="item">

              <div className='field field2'>
                <div className='team-logo' style={{ backgroundImage: `url('${o.scrumImg}')` }}></div>
                <div className='team-info'>
                  <p className="scrum-name">{o.scrumName} <span className="team-name">{o.scrumNo}号</span></p>
                </div>
              </div>

              <div className='field field3'>
                {o.score}/{o.spotlight}
              </div>
              <div className='field field3'>
                {o.yellow}/{o.red}
              </div>
            </div>
          ))}

        </div>
      </div>}

    </>
  );
}
const BAR_1 = "score";
const BAR_2 = "lost";
const BAR_3 = "red";
const BAR_4 = "yellow";


export default {
  BAR_1,
  BAR_2,
  BAR_3,
  BAR_4,
};
import axios from 'axios';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  const error = new Error(response.response.errMessage);
  error.response = response;
  throw error;
}

function checkSuccess(data) {
  if (data.success === true) {
    return data;
  }
  const error = new Error(data.errMessage || '网络错误，请重试');
  error.response = data;
  throw error;
}

const defaultOptions = {
  headers: {
    'Accept': 'application/json',
    'Platform': 'admin-web',
    'Content-Type': 'application/json',
    //'Authorization': 'Bearer ' + localStorage.getItem('beeCloudApiToken'),
  },
  method: 'GET',
}

const beeCloudServer = 'http://127.0.0.1:8000';

export default function request(url, options = {}) {

  if (localStorage.getItem('beeCloudApiToken') !== undefined && localStorage.getItem('beeCloudApiToken')) {
    defaultOptions.headers.Authorization = 'Bearer ' + localStorage.getItem('beeCloudApiToken');
  }

  options = { ...defaultOptions, ...options };
  return axios({ ...options, ...{ url: url } })
    .then(checkStatus)
    .then(checkSuccess)
}

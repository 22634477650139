/**
 * 球队
 */

import { SideBar } from "antd-mobile";
import { useState } from "react"
import Lost from "../component/team/lost";
import Red from "../component/team/red";
import Score from "../component/team/score";
import Yellow from "../component/team/yellow";
import TeamConst from "../consts/team"

export default function Team({ seasonCode }) {
  const [currentBar, setCurrentBar] = useState(TeamConst.BAR_1);
  const [tabs, setTabs] = useState([
    {
      key: TeamConst.BAR_1,
      title: '进球',
    },
    {
      key: TeamConst.BAR_2,
      title: '失球',
    },

    {
      key: TeamConst.BAR_3,
      title: '红牌',
    },
    {
      key: TeamConst.BAR_4,
      title: '黄牌',
    },
  ])

  const renderScrumContent = (currentBar) => {
    if (currentBar === TeamConst.BAR_1) {
      return <Score seasonCode={seasonCode}></Score>
    } else if (currentBar === TeamConst.BAR_2) {
      return <Lost seasonCode={seasonCode}></Lost>
    } else if (currentBar === TeamConst.BAR_3) {
      return <Red seasonCode={seasonCode}></Red>
    } else if (currentBar === TeamConst.BAR_4) {
      return <Yellow seasonCode={seasonCode}></Yellow>
    }

  }

  return (
    <div className="scrum-body">
      <div className='slide-nav'>
        <SideBar style={{ '--width': '80px' }} onChange={setCurrentBar}>
          {tabs.map(item => (
            <SideBar.Item
              key={item.key}
              title={item.title}
            />
          ))}
        </SideBar>
      </div>
      <div className="scrum-content">
        {renderScrumContent(currentBar)}
      </div>
    </div>
  )
}
import { useState, useEffect } from "react"

import { getTeam } from "../../api/api"

export default function Red({ seasonCode }) {

  useEffect(() => {
    getTeam(seasonCode, "red").then(data => {
      setList(data.data)
    })
    return () => { };
  }, [])
  const [list, setList] = useState([
    // { teamName: '曼联', scrumName: '阿莱克斯', total: 1, spotlight: 1, rank: 1, teamImg: "https://t13.baidu.com/it/u=2918543784,4063341038&fm=58&app=10&size=w931&q=75&n=0&f=PNG" }
  ])
  return (
    <div>
      <div className="scrum-table-header">
        <div className='field field1'>排名</div>
        <div className='field field2'>球队</div>
        <div className='field field3'>总计</div>
      </div>
      <div className="scrum-table-content">

        {list.map(o => (
          <div className="item">
            <div className='field field1'>{o.rank}</div>
            <div className='field field2'>
              <div className='team-logo' style={{ backgroundImage: `url('${o.teamImg}')` }}></div>
              <div className='team-info'>
                <p className="scrum-name">{o.scrumName}</p>
                <p className="scrum-name">{o.teamName}</p>
              </div>
            </div>
            <div className='field field3'>
              {o.total}
            </div>

          </div>
        ))}

      </div>
    </div>
  )
}
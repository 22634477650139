/**
 * 射手
 */

import { useEffect, useState } from "react"
import { getScrum } from "../api/api";

/**
 * 助攻
 */
export default function Score({ seasonCode }) {
  useEffect(() => {
    getScrum(seasonCode, "score").then(data => {
      setList(data.data)
    })
    return () => { };
  }, [])
  const [list, setList] = useState([
    // { teamName: '曼联', scrumName: '阿莱克斯', total: 1, spotlight: 1, rank: 1, scrumImg: "https://t13.baidu.com/it/u=2918543784,4063341038&fm=58&app=10&size=w931&q=75&n=0&f=PNG" }
  ])
  return (
    <div>
      <div className="scrum-table-header">
        <div className='field field1'>排名</div>
        <div className='field field2'>球队/球员</div>
        <div className='field field3'>总计</div>
        <div className='field field3'>点球</div>
      </div>
      <div className="scrum-table-content">

        {list.map(o => (
          <div className="item">
            <div className='field field1'>{o.rank}</div>
            <div className='field field2'>
              <div className='team-logo' style={{ backgroundImage: `url('${o.scrumImg}')` }}></div>
              <div className='team-info'>
                <p className="scrum-name">{o.scrumName} <span className="team-name">{o.scrumNo}号</span></p>
                <p className="team-name">{o.teamName}</p>
              </div>
            </div>
            <div className='field field3'>
              {o.total}
            </div>
            <div className='field field3'>
              {o.spotlight}
            </div>
          </div>
        ))}

      </div>
    </div>
  )
}
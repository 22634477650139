import request from './request';

function beeCloudFootballAPi(url, option) {
  return request('/open/api' + url, option)
    .catch(checkLogin);
}

function checkLogin(error) {
  console.log('checkLogin', error.response);
  if (error.response !== undefined && error.response.status === 401) {
    localStorage.removeItem('beeCloudApiToken')
  }
  throw error;
}

/**
 * =========================================football==============================================
 */export function getCourse(season) {
  return beeCloudFootballAPi(`/course/${season}/statistic`, { method: 'GET' });
}
export function getCredit(season) {
  return beeCloudFootballAPi(`/credit/${season}/statistic`, { method: 'GET' });
}

export function getRule(season) {
  return beeCloudFootballAPi(`/rule/${season}`, { method: 'GET' });
}

export function getScrum(season, type) {
  return beeCloudFootballAPi(`/scrum/${season}/${type}`, { method: 'GET' });
}
/**
 * 
 * @param {*} season 
 * @param {*} type 
 * @returns 
 */
export function getTeam(season, type) {
  return beeCloudFootballAPi(`/team/${season}/${type}`, { method: 'GET' });
}


export function getTeamScrum(season, teamCode) {
  return beeCloudFootballAPi(`/team/${season}/${teamCode}/scrum`, { method: 'GET' });
}
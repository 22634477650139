import { useEffect, useState } from "react"
import "./credit.scss"

import { getCredit, getRule } from "../api/api"
import { Popup, SafeArea } from "antd-mobile"
import TeamScrum from "./team-scrum"

/**
 * 积分
 */
export default function Credit({ seasonCode }) {
  useEffect(() => {
    getCredit(seasonCode).then(data => {
      setList(data.data)
    })
    getRule(seasonCode).then(data => {
      setRule(data.data.content)
    })
    return () => { };
  }, [])

  const [visible, setVisible] = useState(false)
  const [team, setTeam] = useState(null)

  const [rule, setRule] = useState("")
  const [list, setList] = useState([
    // { rank: 1, teamName: '胜利冲锋队', total: 1, winScore: 3, lostScore: 10, winMatch: 10, lostMatch: 10, tieMatch: 10, matchTimes: 10, teamLogo: "https://t13.baidu.com/it/u=2918543784,4063341038&fm=58&app=10&size=w931&q=75&n=0&f=PNG" },
  ])
  return (
    <div className="credit-content">
      <div className="table-header">
        <div className='field field1'>排名</div>
        <div className='field field2'>球队</div>
        <div className='field field3'>场次</div>
        <div className='field field4'>胜/平/负</div>
        <div className='field field5'>进球/失球</div>
        <div className='field field6'>积分</div>
      </div>
      <div className="table-content">
        {list.map(o => (
          <div className="item" onClick={() => {
            console.log(111); setVisible(true); setTeam(o)
          }}>
            <div className='field field1'>{o.rank}</div>
            <div className='field field2'>
              <div className='team-logo' style={{ backgroundImage: `url('${o.teamLogo}')` }}></div>
              <span>{o.teamName}</span>
            </div>
            <div className='field field3'>
              {o.matchTimes}
            </div>
            <div className='field field4'>

              <span>{o.winMatch}/{o.tieMatch}/{o.loseMatch}</span>
            </div>
            <div className='field field5'>
              <span>{o.winScore}/{o.loseScore}</span>
            </div>
            <div className='field field6'>
              <span>{o.total}</span>
            </div>
          </div>
        ))}

      </div>
      <div className="rule-content">
        <div dangerouslySetInnerHTML={{ __html: rule }}></div>
        <SafeArea position='bottom' />
      </div>
      <Popup
        closeOnSwipe={true}
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        position='right'
        bodyStyle={{ width: '70vw', overflow: "auto" }}
      >
        {visible && <TeamScrum seasonCode={seasonCode} team={team}></TeamScrum>}
      </Popup>
    </div>
  )
}